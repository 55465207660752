<template>
    <header class="header fuel-header" v-if="!$route.query.needParameters">
        <div class="back">
            <img v-show="$route.name != 'EnrollementForm'" @click="goBack" src="@/assets/img/back.png" id="picto-back" />
        </div>
        <div class="header__title">
            <img :src="$parent.logoURL" id="headerLogo" />
        </div>

        <router-link v-if="this.$route.query.showBasket || $route.name == 'Basket'" id="cart" tag="div" class="" :to="this.base + '/basket'">
            <img src="@/assets/img/picto_cart.png" id="pictoCart"/>
            <span v-show="nbProductsCart>0" id="nbProductsCart">{{nbProductsCart}}</span>
        </router-link>

    </header>
</template>

<script>
    export default {
        name: 'Header',
        props:['nbProductsCart'],
        data() {
            return {
                base: localStorage.getItem("DKC_base")
            }
        },
        methods: {
            redirect: function () {
                this.$router.push(localStorage.getItem("DKC_base"));
            },
            goBack: function () {
                if(this.$route.name == "FuelTicket"){
                    this.$router.push(localStorage.getItem("DKC_base"));
                }
                else{
                    this.$router.go(-1)
                }
                
            }
        },
        watch:{
            nbProductsCart: function() {
                let basketNbProducts = document.getElementById('cart');
                if(basketNbProducts){
                    basketNbProducts.classList.add("shake");
                    setTimeout(function() {
                        basketNbProducts.classList.remove("shake");
                    }, 1000)
                }
                
            }
        }
    }
</script>