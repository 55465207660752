<template>
    <div id="fuel" class="univers">
        <Header v-if="$route.name != 'FuelHome' && $route.name != 'FakeAuthPage'" :nbProductsCart="nbProductsCart"></Header>
        <router-view :key="$route.fullPath"></router-view>
        <Loader v-if="loader.showLoader" :content="loader.content"></Loader>
        <b-modal id="shareModal" ref="shareModal" hide-footer centered :title="$t('sharePopinTitle')">
            <div class="modal-body">
                <img class="bigQrCode" :src="qrCodeImg"/>
            </div>
        </b-modal>
        <Alert ref="Alert" :alertContent="alertContent" :alertVariant="alertVariant"></Alert>
        <ModalSatisfaction v-if="showSatisfactionModal"></ModalSatisfaction>
    </div>
</template>
<script>
    import "@/assets/utils/addtohomescreen.css"
    import "@/assets/utils/addtohomescreen.js"
    import Header from "@/components/Fuel/Header";
    import Loader from "@/components/Common/Loader";
    import Alert from "@/components/Common/Alert";
    import ModalSatisfaction from "@/components/Common/ModalSatisfaction";
    import NoSleep from "nosleep.js";
    import { isNotNull } from "@/assets/js/Utils"
    var App = require("@/assets/js/App")
    var Utils = require("@/assets/js/Utils")
    var Basket = require("@/assets/js/Fuel/Basket")
    var qrcodeGenerator = require('qrcode-generator')
    var MQTT = require("@/assets/js/Fuel/MqttService")
    var noSleep = new NoSleep()

    export default {
        name: "Fuel",
        components: { Header, Loader, Alert, ModalSatisfaction },
        data: function () {
            return {
                base: "",
                basket: {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                nbProductsCart : 0,
                productCrossSell: null,
                alertContent: "",
                qrCodeImg :'',
                alertVariant: "",
                logoURL: "",
                loader:{
                    showLoader: false,
                    content: ""
                },
                showSatisfactionModal : localStorage.getItem("DKC_fuel_satisfaction") == "true" || false
            }
        },
        created() {
            let i18n = {};
            Object.keys(this.langs).forEach( lang => {
                    i18n[lang] = require('@/locales/'+lang+'.json');
                    this.$root.$i18n.setLocaleMessage(lang,i18n[lang].fuel)
                }
            );

            this.loader.content = this.$t("loadingText");

            noSleep.enable();


            if (isNotNull(localStorage.getItem("DKC_lang"))) {
                this.$root.$i18n.locale = localStorage.getItem("DKC_lang");
            } else {
                this.$root.$i18n.locale = 'en';
            }

            if (isNotNull(localStorage.getItem("DKC_complexId")) && isNotNull(localStorage.getItem("DKC_demoId"))) {
                Utils.checkToken();
            }

            App.saveDeviceType();
            App.checkParameters(window.location.pathname, this);
            this.setBase();

            if(!isNotNull(localStorage.getItem("DKC_fuel_token")) && (this.$route.name!="EnrollementForm" || this.$route.name!="FakeAuthPage" )){
                this.$router.push({path : this.base + "/account", query : this.$route.query});
            }

            if( !localStorage.getItem("DKC_fuel_basket")){
                localStorage.setItem("DKC_fuel_basket", JSON.stringify(this.basket));
            }else{
                this.basket = JSON.parse(localStorage.getItem("DKC_fuel_basket"));
            }
            Utils.setAppContext(this);
            window.onunload = function(){
                this.closeHandler();
            }

        },
        mounted() {
            this.checkDemonstrationLogo();
            MQTT.subscribe("PLATE_DETECTED", () => {
                this.$router.push(this.base + "/selection")
                var notificationOptions = {
                    body: this.$t('notificationContent'),
                    icon: "/img/icons/android-chrome-512x512.png",
                    badge: "/img/icons/android-chrome-512x512.png",
                    data: {
                        urlToOpen: this.base + "/selection"
                    }
                }
                var title = this.$t('notificationContent');
                navigator.serviceWorker.getRegistration().then(reg => reg.showNotification(title, notificationOptions))
            })

            MQTT.subscribe("END_DEMO", () => {
                let satisfactionProvided = JSON.parse(localStorage.getItem("DKC_satisfaction"));
                if(!(isNotNull(satisfactionProvided) && satisfactionProvided.FUEL)){
                    this.showSatisfactionModal = true;
                }
            })

        },
        beforeUpdate() {
        },
        updated() {

        },
        methods: {
            setBase: function () {
                var referer = "DKC";
                var univers = localStorage.getItem("DKC_univers");
                var demoId = localStorage.getItem("DKC_demoId");
                var scanType = localStorage.getItem("DKC_shortScanType");
                var complexIdParent = localStorage.getItem("DKC_complexId");

                console.log(complexIdParent)

                if (isNotNull(univers) && isNotNull(demoId) && isNotNull(scanType)) {
                    if (isNotNull(complexIdParent)) {
                        this.base = "/fuel/p=" + referer + "_" + univers + "_" + demoId + "_" + scanType + "_" + complexIdParent;
                    } else {
                        this.base = "/fuel/p=" + referer + "_" + univers + "_" + demoId + "_" + scanType;
                    }
                }
                localStorage.setItem("DKC_base", this.base);
            },
            checkDemonstrationLogo() {
                var path = Utils.getConfig().PATH_IMG + localStorage.getItem("DKC_demoId");
                this.logoURL = Utils.getConfig().DKW_URL + path;
            },
            basketChange: function(){
                this.basket = Basket.getBasket();
                this.count(this.basket.products);
            },
            count: function(products) {
                var count = 0;
                products.forEach(function(product) {
                    count+=product.qty;
                });
                this.nbProductsCart = count;
            },
            closeHandler:function() {
                var self = this;
                if(isNotNull(localStorage.getItem("DKC_fuel_token"))){
                    Basket.updateBasket(function(newBasket) {
                        let tmpProducts = self.basket.products;
                        newBasket.products.forEach(function(element) {
                            tmpProducts.some(function(oldProduct, id) {
                                if(element.product.ean == oldProduct.product.ean && oldProduct.qtyLocked && oldProduct.qty == element.qty){
                                    element.qtyLocked = oldProduct.qtyLocked;
                                    tmpProducts.splice(id, 1)
                                    return true;
                                }
                            })
                        })
                        newBasket.lockedProducts = self.basket.lockedProducts;
                        self.basket = newBasket;
                        localStorage.setItem("DKC_fuel_basket", JSON.stringify(self.basket));
                    })
                }
            },
            showAlert(content, type = ""){
                var variant = "";
                switch (type) {
                    case 'success':
                        variant = "success";
                        break;
                    case 'error':
                        variant = "danger";
                        break;
                    case 'info':
                        variant = "info";
                        break;
                    default:
                        variant = "info";
                }
                this.alertContent = content;
                this.alertVariant = variant;
            },
            share: function(){
                var qr = qrcodeGenerator(0, 'L');
                qr.addData(window.location.origin + localStorage.getItem("DKC_base")+"/account?fuel="+ localStorage.getItem("DKC_fuel_user_favoriteFuel") +"&loyalty=0");
                qr.make();
                this.qrCodeImg = qr.createDataURL(5);
                this.$refs.shareModal.show();
            }
        },
        watch:{
            basket: function() {
                //todo ?
                localStorage.getItem("DKC_fuel_basket");
                this.count(this.basket.products);
            },
            '$route': {
                handler: function() {
                    this.closeHandler();
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>